import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/2d7e0c78/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "alerts"
    }}>{`Alerts`}</h1>
    <p>{`Error – X error message, more here`}</p>
    <p>{`Warn – ! warning message, more here`}</p>
    <p>{`Feedback – define, more here`}</p>
    <p>{`Confirm – define, more here`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <h3 {...{
      "id": "html"
    }}>{`HTML`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h3 {...{
      "id": "markdown"
    }}>{`MARKDOWN`}</h3>
    <p><inlineCode parentName="p">{`here`}</inlineCode></p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <h2 {...{
      "id": "using-different-kinds"
    }}>{`Using different kinds`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      